$activeColor: lighten($selectionColor, 3%);
$activeColorAlt: darken($selectionColorAlt, 15%);

@mixin grid($foreground, $background) {
    background-image: linear-gradient(90deg, $foreground 3%, 4%, $background 4%, $background 100%);
}

.planner-container{
    height: 100%;
    overflow: hidden;
    background-color: $grayLightest;    
    .dark-mode &{
        background-color: $grayDarkest;
    }

    .planner-overlay{
        background: rgba(0, 0, 0, .2);
        position: sticky;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        max-height: 0;
        pointer-events: none;
        opacity: 0;
        transition: opacity .5s;
        z-index: 2;
        &.active{
            opacity: 1;
            max-height: 100%;
        }
        .dark-mode &{
            background: rgba(0, 0, 0, .85);
        }
    }
}

.planner{        
    width: 100%;
    height: 100%;      
    overflow: auto;
    position: relative;    
    padding-bottom: 20px;
    * {
        box-sizing: border-box;
    }
    .planner-row{                
        position: relative; 
        transition: z-index 1.05s .3s;    
        border-top: 1px solid $grayLight;
        border-right: 1px solid $grayLight;
        background-color: $white;  
        margin-bottom: 0px; 
        transition: height .25s;   
        .dark-mode &{
            background-color: $grayDarkest;
            border-color: $grayDarker;
        }                
        .planner-row-header{
            cursor: pointer;
            position: sticky;  
            left: 0;
            top: 0;
            padding: 0 5px;
            border-right: 1px solid $grayLight;
            border-bottom: 1px solid $grayLight;                
            z-index: 2;
            background-color: $white;
            .dark-mode &{
                background-color: $grayDarkest;
                border-color: $grayDarker;
            }
            .planner-row-header-title{
                display: block;
                text-align: right;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 10px;
            }
            .planner-row-header-subtitle{
                position: relative;
                top: -1px;
                display: block;
                text-align: right;
                font-size: smaller;  
                line-height: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;              
            }
        }
        
        > .planner-row-header{
            &:before{
                left: 5px;
                top: 0;
                font-family: "Font Awesome 6 Pro";
                font-weight: 900;
                content: '\f0da';
                color: $gray;
                position: absolute;
            }
        }

        .planner-row-contents{      
            position: absolute;  
            top: 0;
            left: 0;
            padding: 0;
            @include grid($grayLight, transparent);
            border-bottom: 1px solid $grayLight;          
            .dark-mode &{
                @include grid($grayDarker, transparent);  
                border-color: $grayDarker;        
            }
            .planner-date-range-bar{
                position: absolute;
                background-color: lighten($gray, 20%);
                padding: 0 0;
                font-size: smaller;
                line-height: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;  
                text-align: center;
                position: relative;
                z-index: 1;
                border-radius: 10px;
                &:hover{
                    border: 1px solid $white;
                    box-shadow: 0px 0px 0px 1px $activeSelectionColor;
                    .dark-mode &{
                        border: 1px solid $grayDarker;
                        box-shadow: 0px 0px 0px 1px darken($activeSelectionColor, 10%);
                    }
                }   
                &.ghost{
                    position: absolute;
                    top: 5% !important;
                    height: 90% !important;
                    opacity: .25;
                    border-radius: 0 !important;
                    z-index: 0;
                    &:hover{
                        border: none;
                        box-shadow: none;
                    }  
                }
                &.no-resize{
                    .planner-date-range-bar-handle{
                        &.left{
                            width: 100%;
                        }
                    }
                }
                &.modified:not(.ghost){
                    &:after{
                        position: relative;
                        z-index: -1;
                        display: inline-block;
                        font-family: "Font Awesome 6 Pro";    
                        font-weight: 900;
                        font-size: 9px;    
                        height: 100%;
                        width: 10px;
                        vertical-align: top;
                        filter: invert(1) grayscale(1) contrast(200%);                                
                        content: '\f071';
                    }
                }
                &.focused{
                    border: 1px solid $white;
                    box-shadow: 0px 0px 0px 1px $activeSelectionColor;
                    z-index: 1;
                    line-height: 18px;
                    .dark-mode &{
                        border: 1px solid $grayDarker;
                        box-shadow: 0px 0px 0px 1px darken($activeSelectionColor, 10%);
                    }
                    .planner-date-range-bar-handle{
                        &.right{
                            height: 80%;        
                            top: 10%;
                            right: 2px;
                            width: 6px;
                            border-top-right-radius: 10px;
                            border-bottom-right-radius: 10px;
                            border-top: 1px solid $white;
                            border-right: 1px solid $white;
                            border-bottom: 1px solid $white;
                            &:before{
                                right: 0px;  
                            }
                        }
                        &.left{    
                            &:before{
                                font-family: "Font Awesome 6 Pro";    
                                font-weight: 900;
                                font-size: 9px;                            
                                position: absolute;
                                top: 0;
                                width: 12px;
                                height: 100%;
                                line-height: 16px;
                                filter: invert(1) grayscale(1) contrast(200%);                                
                                left: 0px;                        
                                content: '\f142';
                            }
                        }
                    }
                }                
                .planner-date-range-bar-handle{
                    position: absolute;                  
                    top: 0;
                    height: 100%;                    
                    &.left{
                        left: 0;
                        width: calc(100% - 15px);
                        right: auto;
                        background-color: transparent;
                        cursor: pointer;
                    }
                    &.right{
                        left: auto;
                        width: 10px;
                        height: 100%;
                        right: 0;
                        top: 0;
                        transform: none !important;
                        cursor: col-resize;                      
                    }
                }             
            }
        }

        .planner-subrow{
            position: relative;
            animation: delayShowFade 0.25s ease;
        }

        .planner-row-footer{
            position: sticky; 
            width: 600px;
            top: 0px;
            left: 0px;
            z-index: 1;
            .btn{
                padding: 2px 8px;
                font-size: 12px;
            }  
            
            .planner-row-footer-header{
                display: inline-block;
                padding: 0 5px;
                z-index: 0;
                background-color: rgba($white, .75);    
                text-align: right;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;   
                vertical-align: top;  
                margin-bottom: -1px;     
            }

            .planner-row-footer-content{
                display: inline-block;
                padding: 0 5px;
                vertical-align: top;         
            }
        }

        &:nth-child(even){
            background-color: lighten($grayLightest, 3%);
            .planner-row-header{
                background-color: lighten($grayLightest, 3%);
            }
            .planner-row-footer-header{
                background-color: lighten($grayLightest, 3%);
            }
            .dark-mode &{
                background-color: lighten($grayDarkest, 2%);
                .planner-row-header{
                    background-color: lighten($grayDarkest, 2%);
                }
                .planner-row-footer-header{
                    background-color: lighten($grayDarkest, 2%);
                }
            }
        } 

        &:hover, &.expanded{
            background-color: $activeColor;
            .planner-row-header{
                background-color: rgba($activeColor, .75);
            }
            .planner-row-footer-header{
                background-color: $activeColor;
            }
            .dark-mode &{
                background-color: $activeColorAlt;
                .planner-row-header{
                    background-color: rgba($activeColorAlt, .75);
                }
                .planner-row-footer-header{
                    background-color: $activeColorAlt;
                }
            }
        }

        &.expanded{
            z-index: 3;
            background-color: $activeColor;
            > .planner-row-header{
                &:before{
                    transform: rotate(90deg);
                }
            }
        } 
        
        &.removed, .removed{
            .planner-row-contents{
                background-color: rgba($red, .25);
            }
        }

        &.added, .added{
            .planner-row-contents{
                background-color: rgba($blue, .25);
            }
        }
    }

    .planner-timeline{
        background: $white;
        .dark-mode &{
            background-color: $grayDarkest;
        }
        position: sticky;
        top: 0;
        z-index: 3;
        > div{
            display: inline-block;
            top: 100px;           
            vertical-align: top; 
        }
        .planner-timeline-header{
            position: sticky;
            left: 0;
            top: 0;
            z-index: 1;
            background: $white;
            border-right: 1px solid $grayLight;     
            border-bottom: 1px solid $grayLight;            
            .dark-mode &{
                background-color: $grayDarkest;
                border-color: $grayDarker;
            }
        }
        .planner-timeline-contents{
            position: absolute;  
            top: 0;
            left: 0;
            border-bottom: 1px solid $grayLight;      
            background-color: $grayEvenLighter; 
            .dark-mode &{
                border-color: $grayDarker;
            }  
        }
        .planner-timeline-group{
            display: inline-block;
            background: $white;
            .dark-mode &{
                background-color: $grayDarkest;
                border-color: $grayDarker;
            }
            &:last-child{
                &:last-child{
                    border-right: 1px solid $grayLighter;
                    .dark-mode &{
                        border-right: 0px solid $grayDarker;
                    }
                }
            }
            span{
                display: block;             
                text-align: center;                
                border-left: 1px solid $grayLighter;
                border-bottom: 1px solid $grayLighter;
                border-right: 1px solid $grayLight;
                font-weight: bold;           
                .dark-mode &{
                    border-color: $grayDarker;
                }     
            }
            .planner-timeline-point{
                display: inline-block;
                top: 100px;           
                vertical-align: top; 
                text-align: center;
                font-size: 11px;      
                border-left: 1px solid $grayLighter; 
                overflow: hidden;
                > span{
                    background-color: transparent;
                    border: 0 !important;
                    //transform: rotate(-75deg);
                    transform-origin: 52% 55%;   
                }               
                &:last-child{
                    border-right: 1px solid $grayLight;
                }
                .dark-mode &{
                    border-color: $grayDarker;
                }                  
                > div{
                    vertical-align: bottom; 
                }
            }   
        }
    }
}
