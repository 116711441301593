/*-------Mostly global overrides for dark-mode. Individual dark styles are defined in component-specific files. ------------*/
.dark-mode{
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    ::-webkit-scrollbar-track {
        background-color: $grayDarker;
    }
    ::-webkit-scrollbar-thumb {
        background-color: $grayDarkest;
        border-radius: 10px;
        border: 2px solid $grayDarker;
    }
    ::-webkit-scrollbar-corner{
        background-color: $grayDarker;
    }

    .nav-tabs{
        border-color: $grayDarkest;
        .nav-item{
            .nav-link{
                color: $grayMid;
                &.active{
                    background: $grayDarker;
                    border-color: $grayDarkest;
                    border-bottom-color: transparent;
                    color: $white;
                }
                &:hover{
                    border-color: $grayDarkest;
                }
            }
        }
    }

    .close{
        color: $white;
        text-shadow: 0 1px 0 #000;
    }

    &.modal-dialog{
        .modal-header{
            background: $grayDarker !important;
            color: $white;
            border-color: $grayDarkest;
        }
        .modal-content, .modal-footer{
            background: $grayDarker;
            color: $white;
            border-color: $grayDarkest;
        }            
    }

    .bg-light{
        background-color: darken($grayDarker, 3%) !important;
    }

    .alert-danger{
        background-color: $red;
        color: darken($red, 30%);
        border-color:  darken($red, 10%);
    }
    
    .alert-secondary{
        background-color: $gray;
        color: $white;
        border-color: $grayDarker;
    } 

    .alert-warning{
        background-color: darken($yellow, 15%);
        border-color:  darken($yellow, 20%);
    }
}
