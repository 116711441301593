@import "_variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";

@import "_util.scss";
@import "_print.scss";
@import "_forms.scss";
@import "_spinner.scss";
@import "_fetch-indicator.scss";
@import "_error-indicator.scss";
@import "_landing-page.scss";

@import "_page.scss";
@import "_nav.scss";
@import "_table.scss";
@import "_month-picker.scss";
@import "_panels.scss";
@import "_expressions.scss";
@import "_file-checker.scss";
@import "_user-icon.scss";
@import "_help.scss";
@import "_tools-panel.scss";
@import "_tools-panel.scss";
@import "_card-layouts.scss";
@import "_scenarios.scss";
@import "_planner.scss";
@import "_dashboard.scss";
@import "_notes.scss";
@import "_pipelines.scss";
@import "_integrations.scss";
@import "_notifications.scss";

@import "_home.scss";
@import "_demand.scss";
@import "_staffing.scss";
@import "_dark-mode.scss";

:root {
    --domain-color: brown;
    --cell-width: #{$standardCellWidth};
    --row-height: #{$standardRowHeight};
}

body > iframe{
    display: none !important
}