.user-icon{
    height: 30px;
    width: 30px;
    border-radius: 20px !important;
    position: relative;
    top: -4px;
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    position: relative;
    border: 1px solid rgba(0, 0, 0, .15);
    &.small{
        height: 20px;
        width: 20px;
        > span{
            line-height: 20px;
            font-size: 10px;
            height: 20px;
            top: -1px;
        }
    }    
    > span{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-51%);
        width: auto;
        height: 30px;
        line-height: 28px;        
        color: $white;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 1);
        font-size: 14px;
        letter-spacing: -.5px;
        user-select: none;
    }
}

.user-icon-name{
    .user-icon{
        position: relative;
        top: 3px;
    }
    &.font-xs{
        .user-icon{
            top: 1px;
            height: 16px;
            width: 16px;
            > span{
                line-height: 16px;
                font-size: 7px;
                height: 16px;
                top: -1px;
                letter-spacing: 0px;
            }  
        }        
    }
    &.font-sm{
        .user-icon{
            top: 1px;
        }
    }
}