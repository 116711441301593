.page{
    &.tools-mode{
        width: calc(100% - #{$toolsPanelWidth} - 1px);
        margin-left: 0;
        .help-mode &{
            width: calc(100%  - #{$toolsPanelWidth} - #{$helpPanelWidth});
            margin-left: 0;
        }
    }
}

.tools-panel{
    position: absolute;
    right: $toolsPanelWidth * -1;
    width: 0;    
    max-width: 0;
    top: 50px;
    height: calc(100% - 51px);    
    overflow: hidden;
    transition: right .25s;    
    box-sizing: content-box;
    border-left: 0;
    background-color: $white;
    .dark-mode &{
        background-color: $grayDarker;     
        color: $white;   
    }
    .tools-mode &{
        right: 0;        
        width: $toolsPanelWidth;
        max-width: $toolsPanelWidth;
        border-left: 1px solid $grayLight;
        .dark-mode &{
            border-left: 1px solid $grayDarkest;
        }
        .help-mode &{
            right: $helpPanelWidth;        
        }
    }

    .tools-panel-header{
        position: relative;
        border-bottom: 1px solid $grayLight;
        padding: 10px;
        background: $grayEvenLighter;
        height: 46px;
        .dark-mode &{
            background-color: $grayDarker;
            color: $white;
            border-color: $grayDarkest;
        }
        h5{
            margin-bottom: 1px;
        }
        .close{
            position: absolute;
            right: 11px;
            top: 6px;
            padding: 4px 10px 5px 10px;
            &:hover{
                box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05) inset;
            }
            &:active:focus{
                outline: none;
                box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15) inset !important;
            }
            > span:focus{
                outline: none !important;
            }
        }
    }

    .tools-panel-content{
        padding: 10px;
        padding-bottom: 100px;
        font-size: 14px;
        height: calc(100% - 45px);
        overflow: auto;

        background: 
            linear-gradient(#ffffff 33%, rgba(255,255,255, 0)),
            linear-gradient(rgba(255,255,255, 0), #ffffff 66%) 0 100%,
            radial-gradient(farthest-side at 50% 0, rgba(76,85,94, 0.5), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(76,85,94, 0.5), rgba(0,0,0,0)) 0 100%;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-attachment: local, local, scroll, scroll;
        background-size: 100% 30px, 100% 30px, 100% 10px, 100% 10px;

        .dark-mode & {
            background: 
                linear-gradient(#222222 33%, rgba(34,34,34, 0)),
                linear-gradient(rgba(34,34,34, 0), #222222 66%) 0 100%,
                radial-gradient(farthest-side at 50% 0, rgba(0,0,0, 0.5), rgba(0,0,0,0)),
                radial-gradient(farthest-side at 50% 100%, rgba(0,0,0, 0.5), rgba(0,0,0,0)) 0 100%;
            background-color: #222222;
        }
    

        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */
        &::-webkit-scrollbar { /* WebKit */
            display: none; 
        }
        .form-control{
            font-size: 14px;            
        }

        hr{
            margin: 0 0 10px 0;
            .dark-mode &{
                border-color: $grayDarkest;
            }
        }

        .form-check{
            margin-bottom: 10px;
        }

        .tab-pane{
            margin-bottom: 200px;
        }

        .btn-group{
            width: 100%;
            margin-bottom: 10px;            
            .btn{
                font-size: 14px;
                i.btn-icon{
                    font-size: 20px;
                    line-height: 20px;
                    position: relative;
                    top: 2px;
                }
            }
            &.btn-group-sm{
                width: auto;
            }
        }

        .option-switcher{
            position: relative;
            .input-group{
                margin-bottom: 20px;
            }
            .single-select{
                min-width: 200px;
                .single-select__control{
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
                + .input-group-append{
                    button{
                        //border: 1px solid lighten($gray, 10%);
                        .dark-mode &{
                            //border: 1px solid $grayDarkest;
                        }
                    }                
                }
            }
            .btn-group-overflow{
                position: absolute;
                right: 0;
                top: 40px;
                button i{
                    margin-right: 0;
                } 
            }
        }

        .nav-tabs{
            margin-bottom: 10px;          
        }

        .reset-filters{
            float: right;
            position: relative;
            i{

            }   
            &:after{
                content: "\f00d";
                font-weight: 900;
                font-family: "Font Awesome 6 Pro";
                font-size: 14px;
                position: absolute;
                right: 4px;
                top: 10px;
                color: $grayMid;
            }         
            & + .form-group{
                margin-top: 15px;
            }
        }
    }
}