.landing-page{
    position: relative;  
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    background-image: url(../images/page-bg-trans.png);
    background-size: 100% 100%;        
    .dark-mode &, .dark &{
        background-image: url(../images/page-bg-trans.png);
    }   
    
    &.terms{
        > div{
            width: 650px;
        }
    }

    > div{
        background-color: $grayEvenLighter;
        border: 1px solid $borderColor;
        border-radius: 5px;
        box-shadow: $mainShadow;
        position: relative;
        top: -50px;
        width: 410px;
        margin-bottom: 10px;

        .dark & {
            background-color: $grayDarker;
            border: 1px solid $grayDarkest;
            color: $white;
        }    

        .header{
            background-color: $grayDark;
            padding: 5px 10px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            img{
                width: 200px;
            }
        }

        .content{
            padding: 15px;
            a{
                text-decoration: underline;
            }
            .btn-link{
                color: $grayBs;
                .dark & {
                    color: $grayBs;
                    border-color: $grayDark;
                }
                &:hover{
                    text-decoration: none;
                }
            }
            &.overflow{
                height: 360px;
                overflow: auto;
                padding: 15px;
                background-color: rgba(0, 0, 0, .05);
                font-size: 14px;
                line-height: 16px;
                p{
                    margin-bottom: 10px;
                }
            }
        }

        &.footer{
            background-color: transparent;
            border: 0;
            box-shadow: none;
            opacity: .75;
            &:hover{
                opacity: 1;
            }
        }
    }
}

.login-block{
    .btn-link{
        padding: 0;
    }
}