.month-picker{
    position: relative;
    > input{
        cursor: default !important;
    }
    &:after{
        position: absolute;
        pointer-events: none;
        font-family: "Font Awesome 6 Pro";
        content: '\f073';
        font-size: 12px;
        right: 6px;
        top: 9px;
        opacity: .25;
    }
    .month-picker-dropdown{
        position: absolute;
        top: 100%;
        width: 100%;
        min-width: 110px;
        height: 184px;
        right: 0;
        max-height: 0;
        overflow: hidden;
        z-index: 1000;        
        background-color: $white;
        transition: max-height .15s;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        .dark-mode & {
            background-color: $grayDark;
        }
        .header{
            text-align: center;            
            cursor: pointer;
            font-size: 14px;
            height: 28px;
            line-height: 28px;
            .selected{
                font-weight: bold;
                color: $activeSelectionColor;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .years, .months{
            position: absolute;
            width: 100%;
            top: 24px;            
            transition: left .1s;
            padding: 3px 0 3px 3px;
            display: flex;
            flex-wrap: wrap;
            > div{
                cursor: pointer;
                background-color: $grayLightest;
                flex-basis: calc(100%/3 - 3px);
                text-align: center;
                margin-bottom: 3px;
                margin-right: 3px;
                font-size: 13px;
                height: 28px;
                line-height: 28px;
                &:hover{
                    background-color: $grayLight;
                }
                &.selected{
                    background-color: $selectionColor;
                }
                .dark-mode & {
                    background-color: $grayDarker;
                    &:hover{
                        background-color: $grayDarkest;
                    }
                    &.selected{
                        background-color: $activeSelectionColor;
                    }
                }
            }
        }
        .years{
            left: -100%;
        }
        .months{
            left: 0%;
            > div{
                height: 36px;
                line-height: 36px;
            }
        }
        &.year-mode{
            .years{
                left: 0;
            }
            .months{
                left: 100%;
            }
        }
    }
    &.active{
        > input{
            border: 1px solid $activeSelectionColor;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .month-picker-dropdown{
            max-height: 500px;
            border: 1px solid $grayMid;
            box-shadow: $mainShadow;
            .dark-mode & {
                border-color: $grayDarkest;
            }
        }
    }
}