ul.validation-list{
    margin: 1em 0;
    padding: 0 0 0 20px;
    li{
        line-height: 20px;
        span{
            font-weight: bold;
        }
    }
}

.file-checker{
    tr{                
        &:first-child{
            td:not(:first-child){
                font-weight: bold;
                background: $grayLighter;
            }
            td.status{
                font-size: 14px;
                line-height: 21px;
            }
        }

        td{
            &:first-child{
                width: 50px;
                text-align: right;
                font-size: .75em;
                line-height: 21px;
                background: $grayLighter;
            }
            &.error{
                background: #f4d8d8 !important;
                .dark-mode &{
                    background: $red !important;
                }
            }
            &.status{
                width: 50px;
                text-align: center;
                font-size: .75em;
                line-height: 21px;
                text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
                &.New{
                    background: $green !important;
                    color: $white;
                }
                &.Update{
                    background: lighten($rust, 2%) !important;
                    color: $white;
                }
            }
        }                
    }
    
    &.modified{
        border: 2px solid $green;
    }
}