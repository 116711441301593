.notes{
    //padding: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    .history{
        background-color: $white;
        flex-grow: 1;
        flex-shrink: 1;
        min-height: 20px;
        overflow: auto;
        .dark-mode &{
            background-color: $grayDark;
            border-color: $grayDarkest;
        }
        > div{
            padding: 10px;
            border-bottom: 1px solid $borderColor;
            position: relative;
            max-height: 1000vh;
            transition: all .25s;
            .dark-mode &{
                border-color: $grayDarker;
            }
            &:nth-child(even){
                //background-color: $grayEvenLighter;
            }
            > div.meta{
                font-size: 12px;
                text-align: right;                
                display: flex;     
                margin-bottom: 10px;   
                cursor: default;        
                > *{
                    flex-grow: 1;
                    text-align: left;
                    &:last-child{
                        text-align: right;
                        color: $grayMid;
                    }
                }
            }
            > div.editing{
                position: absolute;
                right: 20px;
                top: 30px;
                border-radius: 3px;
                opacity: 0;
                background-color: $grayLighter;
                margin: 0 -10px;
                text-align: right;
                font-size: 12px;
                color: $grayMid;
                transition: all .25s;
                .dark-mode &{
                    background-color: $grayDarker;
                }
                .btn{
                    line-height: 12px;
                    border: 0;
                    color: $grayMid;
                    i{
                        font-size: 12px;                        
                        padding: 0;
                        margin: 0;
                    }
                }
            }
            > p{
                margin-bottom: 0;
                font-size: 13px;
                line-height: 20px;
                cursor: default;
            }
            > .btn-link{
                font-size: 13px;
                display: block;
                border: 0;
                padding: 0;
                color: darken($green, 15%);
            }
            &:hover{
                > div.editing{
                    opacity: 1;
                }
            }
            &.hide{
                opacity: 0;
                max-height: 0;
                padding: 0;
            }
        }
    }
    .controls{
        padding: 5px;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 14px;
        border-bottom: 1px solid $borderColor;
        .dark-mode &{
            border-color: $grayDarkest;
            background-color: $grayDarker;
        }
        .form-control{
            font-size: 14px;
            line-height: 24px;
        }

        .file-browser{
            height: 30px;
            width: auto;
            min-width: 30px;
            height: 30px;
            display: inline-block;
            position: relative;
            padding: 0;
            .file-browser-input{
                position: absolute;
                left: 0;
                top: 0;
                width: 30px; 
                height: 30px;
                opacity: 0;
            }
            .file-browser-label{
                position: relative;
                border: none;
                background-color: transparent;
                padding: 0;
                height: 30px;
                width: auto;
                min-width: 30px;
                line-height: 30px;
                font-size: 13px;
                margin: 0;
                vertical-align: top;
                i{
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    font-size: 15px;
                    color: $grayMid;
                    vertical-align: top;
                    padding: 8px 8px;
                    margin-left: 3px;
                }
                &:hover{
                    i{
                        background-color: rgba(0, 0, 0, .15);
                    }
                }
            }
        }

        .btn-remove{
            background-color: transparent;
            font-size: 15px;
            border-radius: 0;
            color: $grayMid;
            border: none;
            margin-left: 3px;
            i{
                margin: 0;
            }
            &:hover{
                background-color: rgba(0, 0, 0, .15);
            }
        }
    }
}