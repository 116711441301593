.notifications-container {    
    background-color: $teal;

    .notification-item {
        font-size: smaller;
        color: white;
        cursor: pointer;
        position: relative;    
        padding: 4px 1rem;
        i {            
            position: absolute;
            right: 8px;
            top: 6px;
            text-align: right;
            &:hover{
                color: $textColor;
            }
        }
    }
}