.scenario-viewer, .breakout{
    position: absolute;
    top: 50px;
    left: -100vw;
    width: 100vw;
    height: calc(100vh - 50px);
    background-color: $white;
    z-index: 1;
    overflow: hidden;
    .help-mode &{
        width: calc(100% - #{$helpPanelWidth} - 1px);
        margin-left: 0;
        @media print{
            width: 100%;
        }
    }
    .dark-mode &{
        background-color: $grayDarkest;
    }
    &.active{
        left: 0;
    }

    > .resizable-content{
        width: 100%;
        height: 100%;
    }

    &.tools-mode{        
        .help-mode &{
            width: calc(100% - #{$helpPanelWidth} - 1px);
            .tools-panel{
                right: 0;
            } 
        }
        > .resizable-content{
            width: calc(100% - 301px);
        }          
        .tools-panel{
            top: 0;
            height: 100%;
        } 
    }

    .page-title{
        margin: 0;
        height: 46px;
        .page-title-children{
            .close{
                line-height: 30px;
                margin: 0 15px;
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }

    .chart-area{
        height: 100%;
        width: 100%;
        .chart{
            height: 100%;
            width: 100%;
            .demand-graph{
                height: 100%;
                width: 100%;
            }
        }
    }
}

.scenario-item-detail{
    height: 100%;
    background-color: $white;
    .dark-mode &{
        background-color: $grayDarker;
    }
    h5{
        font-size: 24px;
    }
    .prop-set{
        border: 1px solid $borderColor;
        .dark-mode &{
            border-color: $grayDarkest;
        }
        .prop{
            margin-bottom: 1px;
            display: flex;
            &:nth-child(even){
                > div{
                    background-color: darken($grayEvenLighter, 2%);
                    .dark-mode &{
                        background-color: darken($grayDarker, 2%);
                    }
                }                
            }
            > div{
                padding: 2px 6px;
                margin-right: 1px;
                flex-grow: 0;
                flex-shrink: 0;            
                width: 30%;
                height: 40px;
                line-height: 36px;
                vertical-align: middle;
                &:first-child{                
                    width: 20%;
                    flex-grow: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: right;
                    font-weight: bold;            
                }
                &:last-child{                
                    color: lighten($gray, 10%);
                    width: calc(50% - 2px);
                }
                .single-select{
                    line-height: 1.5em;
                }
            }
            &.prop-header{
                > div{
                    background-color: $grayLighter;
                    font-weight: bold;
                    .dark-mode &{
                        background-color: $grayDarkest;
                    }
                    &:last-child{                
                        color: $textColor;
                        .dark-mode &{
                            color: $gray;
                        }
                    }
                }
            }
        }
    }
}

.scenario-advanced{
    .item-list{
        padding-left: 0px;
        list-style: none;
        > li{
            margin-bottom: 20px;
            input[type="checkbox"]{
                margin-right: 5px;
            }
            h6, label{
                margin: 0;
                font-weight: bold;
                font-size: 1em;
                line-height: 1.5em;
            }
            label{
                margin: 0;
            }
            ul{
                padding-left: 0px;
                list-style: none;
                li{
                    font-size: .75em;
                    line-height: 1.5em;
                }
            }
        }
    }
}