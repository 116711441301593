$cardRadius: 4px;
$cardPadding: 12px;
$cardFontSize: 18px;

.page-home{
    margin-top: -16px;
    margin-right: auto;
    margin-left: auto;
    min-height: calc(100vh - 50px);
    position: relative;    
    .dropdown-menu{
        max-width: 268px;
        .dropdown-item{
            white-space: normal !important;
            &[disabled]{
                .dark-mode &{
                    color: $grayMid;
                }   
            }
        }  
    } 
    > .container{
        padding: 15px;
        padding-top: 0;
        height: calc(100vh - 200px);
        position: relative;
        z-index: 2;
        overflow: auto;
    }
    .domain-card{
        min-height: $cardFontSize + ($cardPadding * 2);
        margin-bottom: $cardPadding * 1.25;
        border-radius: $cardRadius;
        &:last-child{
            margin-bottom: 0;
        }
        .domain-card-body{
            min-height: $cardFontSize + ($cardPadding * 2); 
            min-width: 200px;   
            padding: $cardPadding;  
            border: 0;
            background-size: 200% auto;
            background-position: right center;
            flex-shrink: 1;
            flex-grow: 0;
            border-radius: $cardRadius;
            cursor: pointer;
            transition: all .25s;
            background-color: #aaa;
            position: relative;
            background-blend-mode: multiply;
            .domain-card-title{
                margin: 0;
                color: $white;
                text-shadow: 1px 1px 3px rgba(0, 0, 0, .75);
                position: relative;
                z-index: 1;
                word-wrap:  break-word;
            }               
            &:after{
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(135deg, $yellow 0%, rgba($white, 0) 25%);        
                border-radius: $cardRadius - 1;
                opacity: 0;
                transition: all .25s;
                z-index: 0;
            }
            &:hover{ 
                &:after{
                    opacity: 1
                }
            } 
            h5{
                font-size: $cardFontSize;
                line-height: $cardFontSize;
            }
        }
        .domain-deck{
            padding: $cardPadding $cardPadding 0 $cardPadding;
            margin: 0px 7px 0 7px;     
            display: flex;  
            flex-direction: row;
            flex-wrap: wrap; 
            background: transparent;
            position: relative;
            justify-content: left;
            z-index: 0;   
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border: 0px solid #aaa;
            border-top: 0;
            background: $grayLighter;
            .dark-mode &{
                background: $grayDark;
            }
            &.no-parent{
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            > .domain-card{   
                margin-bottom: $cardPadding;
                margin-right: $cardPadding;                
                &:last-child{
                    margin-right: 0px;
                }             
                .domain-card-body{
                    min-width: 199px;
                    max-width: 199px;
                    h5{
                        font-size: $cardFontSize;
                        line-height: $cardFontSize;
                    }
                    &:after{
                        background-image: linear-gradient(135deg, $yellow 0%, rgba($white, 0) 50%);      
                    }
                }
            }
        }     
    }
}