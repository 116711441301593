@use "sass:math";
$cellWidth: $standardCellWidth;
$detailCellHeight: $standardRowHeight;
$cellHeight: 30px;
$timelineCellHeight: 50px;
$headerWidth: $standardCellWidth * 8; //should be multiples of standardCellWidth

$gridColor: $grayLightest;
@mixin gridStaffing($foreground, $background) {
    background-image: linear-gradient(90deg, $foreground 2%, 3%, $background 3%, $background 100%);
}

.tools-mode{
    .group{
        .group-header{
            .btn{
                left: calc(100vw - #{$toolsPanelWidth}) !important;
            }
        }
    }
}

.staffing{    
    .staffing-container{
        overflow: auto;
        position: relative;
        height: calc(100% - 26px);
        background-color: $grayLightest;
        background-size: $standardCellWidth $standardCellWidth;
        .dark-mode &{
            background-color: $grayDarkest;
            .staffing-container-overlay{
                background: rgba(0, 0, 0, .75);
            }
        }
        .staffing-container-overlay{
            background: rgba(0, 0, 0, .35);
            position: sticky;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            pointer-events: none;
            max-height: 0;
            opacity: 0;
            transition: opacity .25s;
            &.active{
                opacity: 1;
                max-height: 100%;
            }
        }
    }
    &.staffing-boxed{
        border: 1px solid $grayLight;
        .dark-mode &{
            border: 1px solid $grayDarkest;
        }
        .staffing-container{
            height: 100%;
            .staffing-container-content{
                padding-bottom: 5px;
            }
        }
    }
    .staffing-container-content{
        overflow: visible;  
        padding-bottom: 100px;   
    }
    .timeline{
        background: $white;
        border-bottom: 2px solid $gridColor;
        position: sticky;
        top: 0;
        z-index: 2;
        height: $timelineCellHeight;           
        background-color: $grayLight;
        background-size: 100px 100px;  
        &.timeline-milestones{
            margin-top: 30px;
        }
        .dark-mode &{
            background-color: $grayDarker;
            border-color: $grayDarkest;
            .timeline-header, .timeline-year, .timeline-year > span,{
                border-color: $grayDarkest;
                background-color: $grayDarker;
            }
            .timeline-month{
                border-color: $grayDarkest;
            }
        }        
        > div{
            display: inline-block;
            top: 100px;           
            vertical-align: top; 
        }
        .timeline-header{
            display: inline-block;
            top: 100px;           
            vertical-align: top; 
            position: sticky;
            left: 0;
            top: 0;
            width: $headerWidth;
            z-index: 1;
            background: $white;
            height: $timelineCellHeight - 2;
            border-right: 1px solid $gridColor;
        }
        .timeline-year{
            display: inline-block;
            top: 100px;           
            vertical-align: top; 
            height: $timelineCellHeight;
            //box-shadow: inset -1px 2px 0px 0px $gridColor;
            background: $white;
            border-bottom: 2px solid $gridColor;
            line-height: 22px;
            font-size: 13px;
            span{
                display: block;
                //width: calc(100% - 1px);                
                text-align: center;                
                background: #fff;
                border-left: 1px solid $gridColor;
                border-bottom: 1px solid $gridColor;
                font-weight: bold;
            }
        }
        .timeline-month{
            position: relative;
            vertical-align: top; 
            display: inline-block;         
            vertical-align: top; 
            text-align: center;
            font-size: 13px;
            line-height: 22px;
            width: $cellWidth;            
            height: math.div($timelineCellHeight, 2);
            border-left: 1px solid $gridColor;
            > div{
                vertical-align: bottom; 
            }
        }     
    }
    .group{
        width: auto;
        border-bottom: 1px solid $gridColor;
        .group-header{
            background: $white;
            border-bottom: 1px solid $gridColor;
            .btn{
                padding: 2px 5px;
                font-size: 12px;
                border-color: transparent;
                background-color: transparent;
                border-radius: 0;
                color: $grayMid;
                margin: 0 0 0 5px;
                &:hover{
                    color: $textColor;
                    background-color: $grayLighter;
                }
                i{
                    margin: 0;
                }
            }            
            > span{
                display: inline-block;
                width: calc(100% - 100px);
                text-align: left;
                position: sticky;
                left: 0;
                padding: 0;
                height: 40px;
                line-height: 40px;
                font-weight: bold;
                font-size: 14px;
            }                        
        }
        &.error{
            color: $red;
            .group-header{
                > span{
                    display: inline;
                    width: auto;
                }
            }
        }  
        .dark-mode &{
            border-color: $grayDarkest;
            .group-header{
                background-color: $grayDarker;
                border-color: $grayDarkest;
            }
        }      
    }
    .item{
        //border-top: 1px solid $gridColor;
        height: $cellHeight;
        position: relative;
        transition: height .25s;        
        z-index: 0;
        cursor: pointer;
        background-color: #fff; 
        @include gridStaffing($grayLight, transparent);
        background-size: $standardCellWidth $standardCellWidth;       
        .dark-mode &{
            background-color: $grayDarker;
            border-color: $grayDarkest;
            @include gridStaffing($grayDarkest, transparent);
            .item-header{
                background-color: $grayDarker;
                border-color: $grayDarkest;
            }            
            &:nth-child(even), &.active{
                @include gridStaffing($grayDarkest, transparent);
                background-color: lighten($grayDarker, 3%);
                .item-header{
                    background-color: lighten($grayDarker, 3%);
                }
                .detail-header-icon{
                    background-color: lighten($grayDarker, 3%);
                }
            }
        }
        &:nth-child(even){
            background-color: $grayEvenLighter;
            @include gridStaffing($grayLight, transparent);
        }
        &.active{
            transition: height .25s;
            height: auto;   
            overflow: visible;
            //border-top: 2px solid $gridColor;
            background-color: $selectionColor;  
            @include gridStaffing($grayLight, transparent);
            z-index: 1;            
            .dark-mode &{
                background-color: $activeSelectionColor;  
                .item-header{
                    background: $activeSelectionColor;              
                }                           
            }
            .item-header{
                font-weight: bold;
                background: $selectionColor;
                &:before{
                    transform: rotate(90deg);
                    color: $grayMid;                    
                }                
            } 
            
            .item-element{
                height: $cellHeight;
            }
        }
        &.total{
            cursor: default;
            .item-header{
                cursor: default;
                font-weight: bold;
                &:before{
                    display: none;
                }
            }    
            .item-element{
                cursor: default;
            }        
        }
        .item-header{
            display: inline-block;
            vertical-align: top;            
            position: sticky;
            left: 0;
            width: $headerWidth;
            background-color: inherit;
            padding: 0 5px 0 47px;
            text-align: right;
            height: $cellHeight;
            line-height: $cellHeight;
            font-size: 12px;
            border-right: 1px solid $gridColor;
            z-index: 1;             
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
            &:before{
                position: absolute;
                left: 10px;
                top: 0;
                font-family: "Font Awesome 6 Pro";    
                font-weight: 900;
                content: '\f0da';
                color: $grayLight;
                transform: none;
                transition: all .25s;
            }
            .btn{
                position: absolute;
                left: 20px;
                top: 3px;
                color: $grayMid;
                padding: 2px 5px;
                font-size: 12px;
                border-color: transparent;
                background-color: transparent;
                border-radius: 0;
                &:hover{
                    color: $textColor;
                    background-color: $grayLighter;
                }
                i{
                    margin: 0;
                }
            }
        }
        .item-element{
            display: inline-block;
            vertical-align: top;
            width: $cellWidth;
            text-align: center;
            height: $cellHeight - .5;
            line-height: $cellHeight + 2;   
            font-size: 11px;
            color: $gray;   
            position: relative;
            cursor: pointer;
            &:after{
                position: absolute;
                right: 2px;
                top: 0;
                font-family: "Font Awesome 6 Pro";    
                font-weight: 900;
                content: '';
                color: $black;
                opacity: .35;
                line-height: 12px;
                .dark-mode &{
                    color: $white;
                }
            }
            &.over{
                background: $over; 
                .dark-mode &{
                    color: $white;
                    background: $overDark; 
                }
                &:after{
                    content: '\f0d8';
                }                       
            }     
            &.under{
                background: $under;   
                .dark-mode &{
                    color: $white;
                    background: $underDark;   
                }
                &:after{
                    content: '\f0d7';
                }             
                &.soft{
                    background: rgba($under, 30%);  
                }
            }     
            &.at{
                background: $at;                
                .dark-mode &{
                    //color: $white;
                    background: $atDark;  
                }
            }    
        }
        &:hover:not(.active):not(.total){
            background-color: $selectionColor;
            .item-header{
                background: $selectionColor;
            }
            .dark-mode &{
                background-color: $activeSelectionColor;           
                .item-header{
                    background: $activeSelectionColor;
                }
            }
        }
        
        .item-detail{
            display: block;
            width: 100%;                   
            position: relative;
            border-top: 1px solid $grayLight;
            border-bottom: 1px solid $grayLight;
            background-color: #fff;   
            transition: height .1s;   
            cursor: default;
            .item-milestones{
                width: 100%;   
                height: $detailCellHeight;
                background-color: $grayMidLight;
                border-top: 1px solid darken($grayMidLight, 10%);
                border-bottom: 1px solid darken($grayMidLight, 10%);
            }
            .dark-mode &{
                background-color: $grayDarker;
                border-color: $grayDarkest;
                .item-milestones{
                    background-color: $grayDarker;
                    border-top: 1px solid darken($grayDarker, 10%);
                    border-bottom: 1px solid darken($grayDarker, 10%);
                }
                .detail-row{            
                    background-color: $grayDarker;
                    border-color: $grayDarkest;    
                    @include gridStaffing($grayDarkest, transparent);  
                    .detail-header{
                        border-color: $grayDarkest;
                        background-color: rgba($grayDarker, .95);
                    }
                    &:nth-child(even){ 
                        background-color: lighten($grayDarker, 3%);
                        .detail-header{
                            background-color: lighten($grayDarker, 3%);
                            border-color: $grayDarkest;
                        }
                    }
                    &.detail-util{
                        background-color: $grayDarker;
                        background-image: none;
                        border-color: $grayDarkest;
                        .detail-header{
                            background-color: rgba($grayDarker, .95);
                            border-color: $grayDarkest;
                        }                        
                        .detail-controls{
                            border-color: $grayDarkest;
                        }
                    }                  
                }
            }                     

            .detail-row {
    @include gridStaffing($grayLight, transparent);
    background-size: $standardCellWidth $standardCellWidth;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    &:nth-child(even) {
        background-color: $grayLightest;
    }
    &.highlight {
        background-color: rgba($selectionColor, .95) !important;
    }
}

.detail-header, .detail-header1 {
    background: $grayLightest;
    height: 100%;
    width: $headerWidth;
    position: sticky;
    left: 0;
    text-align: left; /* Align text to the left for readability */
    padding: 3px 5px;
    font-size: 12px;
    line-height: 15px;
    border-right: 1px solid $gridColor;
    z-index: 3;
    background: rgba($white, .95);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.detail-header-icon{
    &:before{
        position: absolute;
        left: 30px;
        flex-shrink: 0;
        margin-left: auto;
        float: right;
        top: 5px;
        font-family: "Font Awesome 6 Pro";    
        font-weight: 900;
        content: '\f0da';
        color: $grayLight;
        transform: none;
        transition: all .25s;
    }
}
.detail-util-display{
    display: flow-root!important;
}
.detail-header-icon-active {
    &:before{
        position: absolute;
        left: 30px;
        flex-shrink: 0;
        margin-left: auto;
        float: right;
        top: 5px;
        font-family: "Font Awesome 6 Pro";    
        font-weight: 900;
        content: '\f0da';
        color: $grayLight;
        transform: none;
        transition: all .25s;
       -webkit-transform: rotate(90deg)!important;
       transform: rotate(90deg)!important;
       color: #999999!important;
    }
}

.detail-header1 b, .detail-header1 span {
    display: block;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.detail-grid {
    display: flex;
    flex-direction: column;
}
/* Main container for each work item row */
.detail-row {
    display: flex;
    flex-direction: column;
}

/* Container for activities under each work item */
.activity-container {
    display: flex;
    flex-direction: column;
    padding-left: 10px; /* Indent to visually separate activities */
}

.activity-item {
    display: flex;
    align-items: center;
    margin-top: 3px;
    font-size: 12px; /* Adjust font size for better readability */
    color: #333; /* Adjust text color as needed */
}

/* Optional: style each activity to stand out */
.activity-item span {
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

            .detail-row{                          
                @include gridStaffing($grayLight, transparent);
                background-size: $standardCellWidth $standardCellWidth;
                //border-top: 1px solid $grayLight;
                box-sizing: border-box;
                position: relative;  
                &:nth-child(even){
                    background-color: $grayLightest;
                    .detail-header{
                        background: rgba($grayLightest, .95);
                        border-right: 1px solid darken($gridColor, 5%);   
                    }
                }
                &.highlight{
                    background-color: rgba($selectionColor, .95) !important;
                    .detail-header{
                        background: rgba($selectionColor, .95) !important; 
                    }
                }
                &.hidden{
                    .detail-header{
                        pointer-events: none;
                        &:before{
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            z-index: 1000;
                            background-color: rgba(255, 255, 255, .80);
                            .dark-mode &{
                                background-color: rgba(0, 0, 0, .80);
                            }
                        }
                    }

                    .detail-bar{
                        opacity: .25;
                        pointer-events: none;
                    }
                }
                > div{
                    display: inline-block;
                    vertical-align: top;
                    font-size: 12px;                        
                    height: $detailCellHeight;
                    width: $cellWidth;              
                }
                .detail-header{
                    background: $grayLightest;
                    height: 100%;
                    width: $headerWidth;
                    position: sticky;
                    left: 0;      
                    text-align: right;       
                    padding: 3px 5px;
                    font-size: 12px;
                    line-height: 15px;  
                    border-right: 1px solid $gridColor;                    
                    z-index: 3;
                    background: rgba($white, .95);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    b{
                        width: 100%;
                        display: inline-block;
                        font-weight: normal;                        
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;   
                        &.rtl{
                            direction: rtl;
                        }                     
                    }
                    span{
                        color: $grayMid;
                        line-height: 12px;
                        font-size: 11px;
                        width: 100%;
                        display: inline-block;
                        font-weight: normal;                        
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;  
                        &.rtl{                             
                            direction: rtl;
                        }                          
                        &.secondary{
                            display: block;
                            margin-top: 15px;
                            &.by-role{
                                position: absolute;
                                margin-top: 12px;
                                width: 97%;
                            }
                        }
                        &.no-title{
                            display: block;
                            margin-top: 10px;
                        }
                    }
                }
                .detail-element{
                    text-align: center;
                    border-right: 1px solid $grayLighter;
                }
                &.detail-util{
                    background-color: $white;
                    background-image: none;
                    border-top: 1px solid $gridColor;
                    .detail-header{
                        height: $detailCellHeight - 1;
                        font-size: 12px;
                        line-height: 15px;    
                        font-weight: bold;
                        background-color: $white;     
                        span{
                            font-size: 11px;    
                        }                    
                    }
                    .detail-controls{
                        position: sticky;
                        left: $headerWidth;
                        width: 500px;
                        height: $detailCellHeight - 1;
                        padding: 3px 3px;
                        border-left: 1px solid $gridColor;
                        .btn{
                            font-size: 12px;
                            margin-right: 6px;
                            padding: 5px 8px;
                            min-width: 70px;
                            &[disabled]{
                                background: $grayMid;
                                border: 1px solid $grayMid;
                            }
                        }
                        .divider{
                            border-left: 1px solid $borderColor;
                            padding-right: 6px;
                            .dark-mode &{
                                border-left: 1px solid $grayDarkest;
                            }
                        }
                    }
                }
                .detail-grid, .detail-gutter{
                    position: absolute;
                    background: transparent;
                    left: $headerWidth;
                    height: $detailCellHeight;
                    width: calc(100% - #{$headerWidth});
                    //overflow: hidden;
                    .box{
                        background: red;
                        height: 30px;
                    }
                    .detail-bar{
                        position: absolute;
                        background: silver;
                        height: 20px;
                        top: 8px;
                        line-height: 20px;
                        text-align: center;
                        overflow: visible;  
                        min-width: $cellWidth;
                        border-left: 0;
                        border-right: 0;
                        transition: left .1s, width .1s;
                        border-radius: 10px;                        
                        &.detail-bar-tall{
                            height: $detailCellHeight * .6;
                            top: 2%;
                            border-radius: 3px;
                            cursor: pointer;
                            background-color: $grayMid !important;
                            z-index: 1;
                            &:nth-child(3n + 2){
                                top: 20%
                            }
                            &:nth-child(3n + 3){
                                top: 34%;
                            }
                            &:nth-child(3n + 4){
                                top: 2%
                            }
                            &:only-child{
                                top: 20%
                            }
                            > span{
                                position: absolute;
                                border-radius: 0;
                                width: $cellWidth - .5px;
                                margin-left: 1px;
                                height: 86%;
                                top: 7%;
                                line-height: $detailCellHeight * .56;                                
                                text-align: center;
                                background: $white;
                                color: $textColor;                                
                                font-size: 11px;
                                letter-spacing: -.5px;
                                &:first-child{
                                    border-top-left-radius: 3px;
                                    border-bottom-left-radius: 3px;
                                }
                                &:last-child{
                                    width: $cellWidth - 2.5px;
                                    border-top-right-radius: 3px;
                                    border-bottom-right-radius: 3px;
                                }                                
                                &:after{
                                    position: absolute;
                                    right: 2px;
                                    top: -3px;
                                    font-family: "Font Awesome 6 Pro";    
                                    font-weight: 900;
                                    content: '';
                                    color: $black;
                                    opacity: .35;
                                    line-height: 12px;
                                    .dark-mode &{
                                        color: $white;
                                    }
                                }                                
                                &.over{
                                    background: rgba(lighten($over, 25%), 100%); 
                                    .dark-mode &{
                                        //color: $white;
                                        background: $overDark; 
                                    }
                                    &:after{
                                        content: '\f0d8';
                                    }                       
                                }     
                                &.under{
                                    background: rgba(lighten($under, 30%), 100%);   
                                    .dark-mode &{
                                        //color: $white;
                                        background: $underDark;   
                                    }
                                    &:after{
                                        content: '\f0d7';
                                    }             
                                }     
                                &.at{
                                    background: lighten(rgba($at, 100%), 10%);               
                                    .dark-mode &{
                                        //color: $white;
                                        background: lighten(rgba($at, 100%), 10%);  
                                    }
                                }                                 
                                &.na{
                                    left: 0;
                                    width: calc(100% - 2.5px);
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    background: rgba(255, 255, 255, .85) !important;
                                }
                            }                            
                            &:before, &::after{
                                display: none;
                            }
                            &.detail-bar-other{
                                top: math.div($detailCellHeight * .6, 2) + 1px;
                                border-radius: 0;
                                opacity: .5;
                                height: $detailCellHeight * .4;
                                &:hover{
                                    opacity: 1;
                                }
                                &:first-child{
                                    border-top-left-radius: 3px;
                                    border-bottom-left-radius: 3px;
                                }
                                &:last-child{
                                    border-top-right-radius: 3px;
                                    border-bottom-right-radius: 3px;
                                }
                                > span{
                                    position: absolute;
                                    width: $cellWidth - 4px;
                                    left: 2.5px;
                                    margin-left: 0px;
                                    text-align: center;
                                    background: $white;
                                    border-radius: 3px;
                                    font-size: 10px;
                                    letter-spacing: -.5px;
                                    line-height: 11px;
                                    .dark-mode &{
                                        color: $textColor;
                                    }
                                }
                                &:hover, &.active{
                                    border: 0;
                                    box-shadow: none;
                                }
                            }
                        }
                        &.resizing{
                            label, input{
                                visibility: hidden;
                            }
                        }  
                        label{
                            background: inherit;
                            vertical-align: top;
                            margin: 0px;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            background-color: transparent;
                            filter: contrast(200%);
                        }
                        &.dark{
                            label{
                                color: #fff;
                            }
                        }
                        input{
                            text-align: center;
                            height: 18px;
                            margin: 0px;
                            vertical-align: top;
                            width: 30px;
                            border: 1px solid $borderColor;
                            background: rgba(255, 255, 255, .75);  
                            position: absolute;                          
                            left: 50%;
                            transform: translateX(-50%);
                            &:focus{
                                outline: none;
                                border: 1px solid $activeSelectionColor;
                            }
                        }
                        &.locked{
                            label{
                                &:before{
                                    font-family: "Font Awesome 6 Pro";    
                                    font-weight: 900;
                                    font-size: 10px;
                                    content: '\f023';
                                    padding-right: 5px;
                                }
                            }
                        }
                        &.labeled{
                            label{
                                &:after{
                                    font-family: "Font Awesome 6 Pro";    
                                    font-weight: 600;
                                    font-size: 10px;
                                    content: '\f075';
                                    padding-left: 5px;
                                }
                            }
                        }
                        span.react-resizable-handle{   
                            position: absolute;      
                            background: none;
                            padding: 0;
                            width: 13px;
                            line-height: 21px;
                            cursor: ew-resize;;
                            display: none;
                            &.left{
                                left: 0;
                                right: auto;
                                width: calc(100% - 13px);
                                height: 100%;
                                &:before{
                                    font-family: "Font Awesome 6 Pro";    
                                    font-weight: 900;
                                    font-size: 9px;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 15px;
                                    height: 100%;
                                    line-height: 18px;
                                    color: $white;
                                    content: '\f142';
                                }
                            }
                            &.right{
                                transform: none !important;
                                height: 80%;        
                                top: 10%;
                                left: auto;
                                right: 3px;
                                width: 6px;
                                border-top-right-radius: 10px;
                                border-bottom-right-radius: 10px;
                                border-top: 1px solid $white;
                                border-right: 1px solid $white;
                                border-bottom: 1px solid $white;
                                cursor: col-resize;
                            }
                        }
                        &:hover, &.active{                            
                            border: none !important;
                            outline: 1px solid $white;
                            box-shadow: 0px 0px 0px 2px $activeSelectionColor;
                            &:before, &:after{
                                border-color: $activeSelectionColor;
                                top: -5px;
                                left: -1px;
                            }
                        }
                        &.active{                            
                            span.react-resizable-handle{
                                display: block;
                            }
                        }
                    }
                }
                .detail-gutter{
                    top: $detailCellHeight;
                }
            }
        }

        .milestone-flag{
            $msColor1: darken($blue, 5%);
            $msColor2: lighten($blue, 5%);
            position: absolute;
            top: 0;
            height: 100%;
            z-index: 1;
            &:nth-child(even){
                > div{
                    top: 11px;
                    background-color: $msColor2;
                    border: 1px solid $msColor2;
                    &:after {
                        border-left: 15px solid $msColor2;
                    }
                }
                &:before{
                    background-color: $msColor2;
                    top: 11px;
                    height: calc(100% - 11px);
                }
            }
            > div{
                position: absolute;
                top: 3px;
                left: -1px;
                height: 22px;
                max-width: 100px;
                padding: 0 0px 0 5px;                
                color: $white;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                background-color: $msColor1;
                border: 1px solid $msColor1;
                cursor: default;
                font-size: 13px;
                line-height: 20px;
                box-shadow: -1px 1px 5px 3px rgba(0, 0, 0, .15);
                > span{
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &:hover{
                    z-index: 100000;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: calc(100% + 1px);
                    top: -1px;
                    width: 0;
                    height: 0;
                    border-top: 11px solid transparent;
                    border-left: 15px solid $msColor1;
                    border-bottom: 11px solid transparent;
                }
            }
            &:before{
                position: absolute;
                content: ' ';
                left: -1px;
                top: 3px;
                width: 1px;
                height: calc(100% - 3px);
                background-color: $msColor1;
            }
            &:hover{
                z-index: 100000;
            }
        }
    }
}

.workitem-assignments-dialog{
    .modal-title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .section-header{
        clear: both;
        display: block;
        cursor:pointer;      
        position: relative;
        padding: 10px 10px 10px 25px;
        border: 1px solid $borderColor;

        &:before{
            position: absolute;
            font-family: "Font Awesome 6 Pro";    
            font-weight: 900;
            content: '';            
            transform: none;
            transition: all .25s;
            left: 10px;
        }  

        &.collapsed{
            margin-bottom: 15px;            
            &:before{
                content: '\f0da';            
            }        
        }
    
        &.expanded{
            border-bottom: 0 solid $borderColor;
            &:before{
                content: '\f0d7';            
            }  
        }

        &.over{
            background: $over; 
            .dark-mode &{
                color: $white;
                background: $overDark; 
            }                               
        }   

        &.under{
            background: $under;   
            .dark-mode &{
                color: $white;
                background: $underDark;     
            }        
        }  

        &.at{
            background: $at;                
            .dark-mode &{
                color: $white;
                background: $atDark;  
            }
        }

        &.other{
            background: rgba($gray, .15); 
        }

        .ratio{
            > span{
                font-size: smaller;
                opacity: .5;
            }
        }

        .title{
            i{
                font-size: smaller;
                opacity: .5; 
                display: inline-block;
                font-style: normal;
                margin-left: 5px;
            }
        }
    }

    .section-content{
        border: 1px solid $borderColor;
        border-top: 0;
        margin-bottom: 15px;  
    }

    .assignment-listing{
        border: 1px solid $borderColor;
        padding: 15px;
        margin-bottom: 20px;

        .page-title{
            h3{
                font-size: 1em;
            }
        }

        .form-area{
            margin: 0px;

            .form-group{
                margin-bottom: 0;
                .single-select{
                    width: calc(100% - 230px);
                }

                .btn{
                    width: 100px;
                    margin-left: 10px;
                }

                .btn-danger {
                    position: relative;
                    margin-left: 18px;
                    &:before{
                        content: '';
                        position: absolute;
                        left: -10px;
                        top: 0;
                        height: 30px;
                        width: 1px;
                        border-left: 1px solid $borderColor;
                    }
                }
            }
        }

        .react-bootstrap-table-pagination{
            display: none;
        }
    }

    &.dark-mode{
        .section-header, .assignment-listing{
            border-color: $grayDarkest;            
            .form-area{
                border-color: $grayDarkest;                     
            }
        }
    }
}

.assignments-dialog-modal{
    td.column-reference{
        span{
            display: inline-block;
            min-width: 50px;
            text-align: center;
            padding: 0 3px;
            border-radius: 5px;
            &:after{
                margin-left: 3px;
                font-family: "Font Awesome 6 Pro";    
                font-weight: 900;
                content: '';
                color: $black;
                opacity: .35;
                line-height: 12px;
                .dark-mode &{
                    color: $white;
                }
            } 
            &.over{
                background-color: rgba(lighten($over, 25%), 100%); 
                .dark-mode &{
                    background-color: $overDark; 
                }
                &:after{
                    content: '\f0d8';
                }                       
            }     
            &.under{
                background: rgba(lighten($under, 30%), 100%);   
                .dark-mode &{
                    background-color: $underDark;   
                }
                &:after{
                    content: '\f0d7';
                }             
            }     
            &.at{
                background-color: lighten(rgba($at, 100%), 10%);               
                .dark-mode &{
                    background-color: lighten(rgba($at, 100%), 10%);  
                }
            } 
        }
    }

    .popover{
        max-width: 450px;        
        .popover-body{
            padding: 5px;
        }
    }

    .popover-calendar{
        > div{
            border-bottom: 1px solid $borderColor;
            padding: 0;
            height: 25px;
            &:last-child{
                border-bottom: 0;
            }
            &.popover-calendar-header{
                background-color: $grayLighter;
            }
            label{
                width: 40px;
                height: 100%;
                margin-bottom: 0;
                padding: 2px;
                border-right: 1px solid $borderColor;
                vertical-align: top;
            }
            span{
                display: inline-block;
                vertical-align: top;
                height: 100%;
                width: 30px;
                text-align: center;
                border-right: 1px solid $borderColor;
                padding: 2px 0;
                font-size: 12px;
                position: relative;
                &:last-child{
                    border-right: 0;
                }
                &:after{
                    position: absolute;
                    right: 0px;
                    top: -2px;
                    margin-left: 3px;
                    font-family: "Font Awesome 6 Pro";    
                    font-weight: 900;
                    content: '';
                    color: $black;
                    opacity: .35;
                    line-height: 12px;
                    .dark-mode &{
                        color: $white;
                    }
                }                 
                &.over{
                    background-color: rgba(lighten($over, 25%), 100%); 
                    &:after{
                        content: '\f0d8';
                    }                       
                }     
                &.under{
                    background: rgba(lighten($under, 30%), 100%);   
                    &:after{
                        content: '\f0d7';
                    }             
                }     
                &.at{
                    background-color: lighten(rgba($at, 100%), 10%);               
                }
            }
        }
    }

    &.dark-mode{
        td.column-reference{
            span{
                color: $white;
                &.over{
                    background-color: $overDark;                      
                }     
                &.under{
                    background-color: $underDark;                       
                }     
                &.at{
                    background-color: lighten(rgba($at, 100%), 10%);  
                }                 
            }
        }

        .popover{        
            background-color: $grayDark !important;
            .popover-calendar{
                .popover-calendar-header{
                    background-color: $grayDarker;
                }
                > div{
                    border-color: $grayDarkest;
                    label{
                        color: $white;
                        border-color: $grayDarkest;
                    }
                    span{
                        border-color: $grayDarkest;
                        color: $white;
                        &.over{
                            background-color: $overDark;                         
                        }     
                        &.under{
                            background-color: $underDark;   
                        }     
                        &.at{
                            background-color: lighten(rgba($at, 100%), 10%);  
                        }                        
                    }
                }
            }

            .arrow{
                &:after{
                    border-left-color: $grayDark !important;
                }                
            }
        }
    }
}
